<template>
    <div style="padding-top: 8em;">
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
            <v-layout column>
                <div>
                <strong>{{ snackbar.title }}</strong>
                </div>
                <div>{{ snackbar.text }}</div>
            </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
            <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        <section class="intro clearfix header_service">
            <div class="container">
                <div class="row">
                    <div class="overlay-green"></div>
                            <div class="col-md-12 intro-info text-left" style="z-index: 1;">
                                <h1 class="text-uppercase bold text-white mb-4 text-shadow ls-1">CEK NAMA PT ANDA DISINI!</h1>
                                <p class="h5 mb-5 text-white text-shadow">
                                    Legalpedia akan segera membantu Anda melakukan pengecekan nama PT pilihan Anda.
                                </p>
                                <p class="bold mb-2 text-white font-size-14">Disclaimer</p>
                                <p class="text-white font-size-14">
                                    Layanan pengecekan nama PT akan dilakukan melalui website Kemenkumham, namun hasil dari pengecekan nama PT bukan merupakan janji kesediaan nama tersebut. Untuk mendapatkan hasil yang lebih akurat disarankan untuk melakukan pengecekan dan pemesanan nama PT dengan membayar PNBP. Legalitas perusahaan merupakan dokumen rahasia yang tidak dapat secara sembarangan diberikan kepada pihak yang tidak berkaitan/berkepentingan dengan kepengurusan secara langsung. Legalpedia tidak dapat membantu untuk melakukan pengecekan terhadap legalitas perusahaan tersebut dan tidak berwenang untuk menginformasikan detail legalitas PT yang sudah berdiri. Dalam hal ingin memeriksa suatu legalitas perusahaan, kiranya dapat mengajukan permohonan formal kepada perusahaan tersebut.
                                </p>
                            <div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-100">
            <div class="container">
                <div class="top-over">
                    <v-row align="center" justify="center">
                        <div class="col-sm-6">
                            <div class="w-100" style="min-height: 269px;">
                                <h5 class="text-danger font-weight-semibold">Cek Nama PT</h5>
                                <v-text-field
                                    solo
                                    class="border-12"
                                    placeholder=" Nama PT"
                                    hide-details=""
                                    v-model="company_name"
                                ></v-text-field>
                                <p class="font-size-14 text-muted">
                                    <v-icon small>mdi-exclamation</v-icon>
                                Nama PT minimal menggunakan 3 suku kata
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-5 offset-sm-1">
                            <h5 class="text-danger font-weight-semibold">Data Diri</h5>
                            <v-text-field
                                solo
                                class="border-12"
                                placeholder=" Nama "
                                v-model="name"
                            ></v-text-field>
                            <v-text-field
                                solo
                                class="border-12"
                                placeholder="Email"
                                type="email"
                                v-model="email"
                            ></v-text-field>
                            <v-text-field
                                solo
                                class="border-12"
                                placeholder="Nomor Telepon"
                                v-model="no_telp"
                            ></v-text-field>
                        </div>
                        <div class="col-md-12">
                            <v-btn block color="success" class="border-12" @click="submit">
                                <v-icon small>mdi-magnify</v-icon>
                                Cek Nama PT
                            </v-btn>
                        </div>
                    </v-row>
                </div>
            </div>
        </section>
        <!-- <contact-us></contact-us> -->
    </div>
</template>
<script>
import { mapState } from 'vuex'
import ContactUs from "@/components/layout_front/ContactUs.vue"
import {check_pt} from "@/backend-api/check_pt"

export default {
    components: {
        ContactUs
    },
    data: () => ({
        snackbar: {
        color: null,
        icon: null,
        mode: null,
        position: "top",
        text: null,
        timeout: 7500,
        title: null,
        visible: false
        },
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Konsultasi Hukum',
                disabled: true,
            }
        ],
        siteKey: '',
        company_name: '',
        name: '',
        email: '',
        no_telp: ''
    }),
    methods:{
        clear(){
            this.company_name = ''
            this.name = ''
            this.email = ''
            this.no_telp = ''
        },
        async submit() {
            if (this.company_name.trim() == '' || this.name.trim() == '' || this.email.trim() == '' || this.no_telp.trim() == '') {
                this.snackbar = {
                    color: "info",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Info",
                    text: 'Please fill your fileds and Cek Nama PT',
                    visible: true
                };

                return false
            }
            this.$store.dispatch('setOverlay', true)
            var reqBody = {
                'company_name' : this.company_name,
                'name': this.name,
                'email': this.email,
                'no_telp': this.no_telp
            }
            const respData = await check_pt.store('', reqBody, false, false, false, false)
            if (respData.status === 200) {
                this.snackbar = {
                    color: "success",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Success",
                    text: 'Terimakasih sudah menggunakan fitur cek nama pt kami, team kami nanti akan menghubungi anda lebih lanjut',
                    visible: true
                };
                this.clear()
                this.$store.dispatch('setOverlay', false)
            } else {
                this.$store.dispatch('setOverlay', false)

            }
        },
        handleSuccess(){

        },
        handleError(){

        }
    },
    async mounted() { 
        this.$store.dispatch('setOverlay', false)
    },
    watch: {
    }
}
</script>

<style scoped>

</style>